import { alphabeticalSort } from 'services/util/abstract'
import { Icon } from 'components/icon/Icon'
import AeditLink from 'services/link'
import DropdownMenu from 'components/dropdown'

const SolutionsMenu = ({ concerns, procedures, isShown, setShown, setClosed }) => (
  <DropdownMenu
    className="absolute top-full bg-white left-0 right-0 py-8 px-12 w-full overflow-hidden"
    isShown={isShown}
    setClosed={setClosed}
    onMouseEnter={setShown}
    onMouseLeave={setClosed}>
    <div className="flex">
      <DropdownMenu.Box className="right-0 left-0 text-black mr-24">
        <span className="relative flex mb-4">
          <Icon name="heart" className="mr-4" />
          <p className="beuro-text font-bold">Concerns</p>
        </span>

        <ul className="mb-2 ml-4">
          {alphabeticalSort(concerns, 'value').map((c, i) => (
            <li className="mb-4 capitalize transition-all hover:opacity-50" key={i}>
              <AeditLink className="t-body" pathname="/concern/[slug]" as={`/concern/${c.slug}`}>
                {c.value}
              </AeditLink>
            </li>
          ))}
        </ul>
        <AeditLink className="t-overline underline dark-gray ml-4" pathname="/concerns">
          View All
        </AeditLink>
      </DropdownMenu.Box>

      <DropdownMenu.Box className="text-black">
        <span className="relative flex mb-4">
          <Icon name="clipboard" className="mr-4" />
          <p className="beuro-text font-bold">Procedures</p>
        </span>

        <ul className="mb-2 ml-4">
          {alphabeticalSort(procedures, 'value').map((p, i) => (
            <li className="mb-4 capitalize transition-all hover:opacity-50" key={i}>
              <AeditLink
                className="t-body"
                pathname="/procedure/[slug]"
                as={`/procedure/${p.slug}`}>
                {p.value}
              </AeditLink>
            </li>
          ))}
        </ul>
        <AeditLink pathname="/procedures" className="t-overline underline dark-gray ml-4">
          View All
        </AeditLink>
      </DropdownMenu.Box>
    </div>
  </DropdownMenu>
)

export default SolutionsMenu
